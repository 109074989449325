import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Link, Container, Typography, Stack, useTheme } from '@mui/material';
// components
import LogoCapitalization from '../../components/LogoCapitalization';
import SocialsButton from '../../components/SocialsButton';
// Header and Footer
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
// redux
import { useSelector } from '../../redux/store';

// ----------------------------------------------------------------------

export default function MainLayout() {
   const { settings } = useSelector((state) => state.settings);

   const { pathname } = useLocation();

   const isHome = pathname === '/';

   const theme = useTheme();

   return (
      <Stack sx={{ minHeight: 1 }}>
         <MainHeader />

         <Outlet />

         <Box sx={{ flexGrow: 1 }} />

         {settings.certificate.isCertificated && (
            <Box
               sx={{
                  bgcolor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
                  pb: 5,
                  pt: 1,
                  textAlign: 'center',
                  position: 'relative',
               }}
            >
               <Container sx={{ maxWidth: '800px !important' }}>
                  <LogoCapitalization sx={{ mb: 2, mx: 'auto' }} />

                  <Typography
                     variant="caption"
                     component="span"
                     color={'text.secondary'}
                     sx={{ lineHeight: 1.25, opacity: 0.8 }}
                  >
                     Título de Capitalização da Modalidade Filantropia Premiável de Contribuição Única, emitido pela
                     CAPEMISA Capitalização S/A, CNPJ 14.056.028/0001-55, aprovado pelo Processo SUSEP que consta no
                     Título. SAC 0800 940 1130. OUVIDORIA 0800 707 4936, de segunda a sexta-feira, das 8h às 17h. É
                     proibida a venda de título de capitalização a menores de dezesseis anos. O valor não exigido dentro
                     do prazo prescricional, estabelecido pela legislação em vigor, acarretará a perda desse direito. A
                     aquisição deste título implica a cessão de 100% do direito de resgate à FEDERAÇÃO NACIONAL DAS
                     ASSOCIAÇÕES PESTALOZZI – FENAPESTALOZZI, certificada nos termos da legislação em vigor. Antes de
                     contratar consulte previamente as Condições Gerais. As condições contratuais/regulamento deste
                     produto protocolizadas pela sociedade junto à SUSEP poderão ser consultadas no endereço eletrônico
                     www.susep.gov.br, de acordo com o número de processo constante da proposta. Consulte as informações
                     legais da Resolução CNSP 382/2020 em www.capemisa.com.br. Prêmios líquidos de imposto de renda.
                     Confira o resultado dos sorteios e as condições de participação em {window.location.href}
                     <br />
                     Imagens meramente ilustrativas.
                     <br />
                     <Typography sx={{ marginTop: '20px !important;' }}>
                        Fale Conosco:{' '}
                        <Link href="https://wa.me/+5519999969999" target="_blank">
                           <b>SORTEAMOS</b>
                        </Link>
                     </Typography>
                     {/* <br /> Feito com 💖 por &nbsp;
                     <Link href="https://sorteamos.com.br/">sorteamos.com.br</Link> */}
                  </Typography>

                  <Stack
                     my={2}
                     sx={{
                        width: '100%',
                     }}
                  >
                     <SocialsButton initialColor />
                  </Stack>
               </Container>
            </Box>
         )}

         {/* {!isHome ? (
            <MainFooter />
         ) : (
            <Box
               sx={{
                  bgcolor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
                  pb: 5,
                  pt: 1,
                  textAlign: 'center',
                  position: 'relative',
               }}
            >
               <Container sx={{ maxWidth: '800px !important' }}>
                  <LogoCapitalization sx={{ mb: 2, mx: 'auto' }} />

                  <Typography
                     variant="caption"
                     component="span"
                     color={'text.secondary'}
                     sx={{ lineHeight: 1.25, opacity: 0.8 }}
                  >
                     Título de Capitalização da Modalidade Filantropia Premiável de Contribuição Única, emitido pela
                     CAPEMISA Capitalização S/A, CNPJ 14.056.028/0001-55, aprovado pelo Processo SUSEP que consta no
                     Título. SAC 0800 940 1130. OUVIDORIA 0800 707 4936, de segunda a sexta-feira, das 8h às 17h. É
                     proibida a venda de título de capitalização a menores de dezesseis anos. O valor não exigido dentro
                     do prazo prescricional, estabelecido pela legislação em vigor, acarretará a perda desse direito. A
                     aquisição deste título implica a cessão de 100% do direito de resgate à FEDERAÇÃO NACIONAL DAS
                     ASSOCIAÇÕES PESTALOZZI – FENAPESTALOZZI, certificada nos termos da legislação em vigor. Antes de
                     contratar consulte previamente as Condições Gerais. As condições contratuais/regulamento deste
                     produto protocolizadas pela sociedade junto à SUSEP poderão ser consultadas no endereço eletrônico
                     www.susep.gov.br, de acordo com o número de processo constante da proposta. Consulte as informações
                     legais da Resolução CNSP 382/2020 em www.capemisa.com.br. Prêmios líquidos de imposto de renda.
                     Confira o resultado dos sorteios e as condições de participação em {window.location.href}
                     <br />
                     Imagens meramente ilustrativas.
                     <br />
                     <Typography sx={{ marginTop: '20px !important;' }}>
                        Fale Conosco:{' '}
                        <Link href="https://lp.phocuscap.com.br/solicite-contato-cap" target="_blank">
                           <b>Phocus CAP</b>
                        </Link>
                     </Typography>
                  </Typography>
               </Container>
            </Box>
         )} */}
      </Stack>
   );
}
