import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

import { _bankingContacts, _invoicesProduct } from '../../_mock';
//
import { dispatch } from '../store';

const initialState = {
   error: null,
   hasLoaded: false,
   product: null,
   orders: [],
   order: null,
};

const slice = createSlice({
   name: 'order',
   initialState,
   reducers: {
      // START LOADING
      startLoading(state) {
         state.isLoading = true;
      },

      // HAS ERROR
      hasError(state, action) {
         state.isLoading = false;
         state.error = action.payload;
      },

      getOrdersSuccess(state, action) {
         state.isLoading = false;
         state.orders = action.payload;
      },

      getProductSuccess(state, action) {
         state.isLoading = false;
         state.product = action.payload;
      },
   },
});

// Reducers
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getOrders(id) {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         // const response = await axios.get(`/api/product/${id}/orders?&page=${page}&rowsPerPage=${rowsPerPage}`);

         const response = await axios.get(`/api/product/${id}/orders`);
         dispatch(slice.actions.getOrdersSuccess(response.data.orders));
         dispatch(slice.actions.getProductSuccess(response.data.product));

         // aqui eu quero fazer uma chamada em um mokup para reproduzir
         // gerar uma quantidade onde traga lentidao > 5seg

         // apos identificar a lentidao, vamos reparticionar as paginas
         // ira trazer apenas o que esta mostrando na tela
         // a busca do filtro de numeros por exemplo, devera ser feita no banco
         // de dados e nao mais no state, tendo em vista que teremos apenas as paginas
         // ja carregadas ou apenas a que está em tela.

         // a chamada na api deve trazer já o total de geral
         // após feito isso, deveremos reparticionar as chamadas

         // quando for realizar o filtro, devemos buscar no banco e trazer
         // apenas os resultados impactantes. Pode ser que perca um pouco a perfomance nos filtros
         // mas será impercepitivel

         // const response = await axios.get(`/api/product/${id}/orders`);
         // dispatch(slice.actions.getOrdersSuccess(response.data.orders));
         // dispatch(slice.actions.getProductSuccess(response.data.product));

         // console.log('AQUIOH', response.data);
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

export function getOrdersPagination(id, rowsPerPage, page, filters) {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get(
            `/api/product/${id}/orders?&page=${page}&rowsPerPage=${rowsPerPage}&filterName=${filters.filterName}&filterNumber=${filters.filterNumber}&filterAffiliate=${filters.filterAffiliate}&filterStatus=${filters.filterStatus}&filterPaymentId=${filters.filterPaymentId}&filterPhone=${filters.filterPhone}&filterOrderId=${filters.filterOrderId}`
         );
         dispatch(slice.actions.getOrdersSuccess(response.data.orders));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

export function getOrdersPaginationProductTotal(id, rowsPerPage, page) {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get(`/api/product/${id}/orders/total?&page=${page}&rowsPerPage=${rowsPerPage}`);
         dispatch(slice.actions.getProductSuccess(response.data.product));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

export function emptyOrders() {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         dispatch(slice.actions.getOrdersSuccess([]));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

export function emptyProduct() {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         dispatch(slice.actions.getProductSuccess(null));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}
